
.main_menu_container{
   position: fixed;
   height: 100vh;
   width: 100%;
    top: 0;
        left: 0;
        right: 0;
        bottom: 0;
       .transparent_close{
        position: absolute;
        width: 100vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.331);
        z-index: 99;
    }

    .menu-container{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 400px;
background: rgb(7,38,37);
background: linear-gradient(103deg, rgba(7,38,37,1) 0%, rgba(20,120,118,0.9472163865546218) 71%, rgba(13,62,60,1) 100%);
    z-index: 100;
    padding: 30px;
    transition: all 0.5s ease;
    
    @media screen and (max-width:600px) {
        width: 100%; 
    }
    ul{
        list-style: none;
        margin-top: 20px;
        li{
            font-size: 18px;
            margin-bottom: 20px;
            cursor: pointer;
            padding: 8px;

            .action-btn{
                display: flex;
                cursor: pointer;
                svg{
                color:#2cb5b2;
                margin-left:0px;
                font-size: 20px !important;
                 &.svg-right-space{
                   margin-right: 4px;
                 }
               }
               select{
                background:transparent;
                color: white;
                border: none;
                outline: none;
                cursor: pointer;
                 option{
                cursor: pointer;
                color: black !important;
                 &:focus{
                    color: black;
                 }
                }
               }
              
            }
        }
    }
    h1{
        font-size: 50px;
        svg{
            font-size: 32px;
        }
    }

}
}

.menu-icon-sp{
    justify-content: center !important;
    flex-direction: row !important;
    i {
        font-size: 14px;
        color: white;
        cursor: pointer;
    }
}