.change-container{
    background-color: white;
    min-height: 300px;
    .change-wrapper{
    display: grid;
    place-content: center;
    text-align: center;
    margin-top: 40px;
    width: 300px;
    margin:0 auto;
    margin-top: 40px;
    span{
        font-weight: bold;
    }
    .change-dialog{
        font-weight: 400;
        text-align: justify;
        color: #b4b4b4;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    form{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
         width: 300px;
         gap: 10px;
         margin-bottom: 200px;

         input{
            padding:8px;
            border: 1px solid #b4b4b4;
            text-align: center;
            border-radius: 4px;
            height: 45px;
            &:focus{
                outline: none;
                border: 1px solid #2cb5b2;

            }
         }
         button{
            background-color: #2cb5b2;
            border-radius: 4px;
            padding:10px;
            border: none;
            color: white;
            cursor:pointer;

         }

    }
    }
}