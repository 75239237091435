.customer_banner-container{
    background-image: url('../../../assets/Images/customer_banner.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 300px;
    width: 100%;
    display: grid;
    place-content: center;
    text-align: center;
    position: relative;
    .banner_content{
         position: relative;
         z-index: 2;
        h1{
        font-size: 50px;
        @media screen and (max-width:800px) {
            font-size: 32px;
        }
    }
    span{
        font-size: 25px;
        @media screen and (max-width:800px) {
            font-size: 15px;
        }
    }

    }
     
    .banner_layer{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.2) 100%);
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
}
