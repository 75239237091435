.main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 100px 20px;
    @media screen and (max-width: 768px) {
        padding: 50px 20px;
        
    }
    .wrapper{
        .Intro-title{
            margin-bottom: 60px;
            @media screen and (max-width: 768px) {
                margin-bottom: 30px;
                
            }
            h1{
                font-size: 25px;
                font-weight: 700;
                color: #2A2A2A;
                span{
                    color: #2CB5B2;
                }
            
            }
            p{
                font-size: 35px;
                font-weight: 600;
                color: #2CB5B2;
                @media screen and (max-width: 768px) {
                    font-size: 25px;
                    
                }
            }
        }
        .display-holder{
            display: flex;
            width: 100%;
            gap: 30px;
            @media screen and (max-width: 768px) {
                flex-direction: column;  
            }
            .infor-form{
                width: 400px;
                @media screen and (max-width: 768px) {
                    width: 100%; 
                    
                }
                .input-container{
                    margin-bottom: 3px;
                    label{
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        color: #2CB5B2;
                        outline: none;
                        display: block;
    
                    }
                    input{
                        width: 100% !important;
                        height: 54px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        padding: 0 10px;
                        border: 1px solid #2CB5B2;
                        color: #2A2A2A !important;
                        outline: none;
                    }
                    select{
                        width: 100%;
                        height: 54px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        padding: 0 10px;
                        border: 1px solid #2CB5B2;
                        outline: none;
                        background: white !important;
                        color: #2A2A2A !important;
    
                    }

                }
             
                button{
                    width: 100%;
                    height: 54px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 0 10px;
                    background-color: #fff;
                    cursor: pointer;
                    border: 1px solid #2CB5B2;
                    font-weight: 700;
                    background-color: #2CB5B2;
                    color: white;
                    margin-top: 24px;
    
                }
                
            }
        };
    }
}

.infor-form input[type="date"] {
    width: 100%;
    -webkit-appearance: textfield; /* Add this for Safari */
}

