.forget-container{
    background-color: white;
    min-height: 300px;
    .forget-wrapper{
    display: grid;
    place-content: center;
    text-align: center;
    margin-top: 40px;
    margin:0 auto;
    margin-top: 40px;
    span{
        font-weight: bold;
    }
    .forget-dialog{
        font-weight: 400;
        text-align: justify;
        color: #b4b4b4;
        margin-top: 15px;
        margin-bottom: 15px;
    } 
    .forget-action-container{
        display: flex;
        gap: 100px;
        margin-bottom: 100px;
        color: black;
        @media screen and (max-width: 700px) {
            display: flex;
            flex-direction: column;
            padding: 30px;
            width: 95vw;
        }
        .section1{
            flex: 3;
            form{
                width: 100% !important;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                width: 370pxs;
                gap: 10px;
                margin-bottom: 6px;
    
                 label{
                    color: #2cb5b2;
                    font-size: 13px;
                    text-align: start;
                    font-weight: bold;
                 }
                 input{
                    padding:8px;
                    border: 1px solid #b4b4b4;
                    text-align: start;
                    border-radius: 4px;
                    height: 52px;
                    margin-bottom: 27px;
                    min-width: 300px;
                    &:focus{
                        outline: none;
                        border: 1px solid #2cb5b2;
        
                    }
                 }
                 button{
                    background-color: #2cb5b2;
                    border-radius: 4px;
                    padding:10px;
                    border: none;
                    color: white;
                    cursor:pointer;
                    height: 45px;
                 }
                 .vb_item{
                    display: flex ;
                    align-items: center;
                    padding:8px;
                    border: 1px solid #b4b4b4;
                    text-align: center;
                    border-radius: 4px;
                    height: 52px;
                    margin-bottom: 27px;
                    svg{
                        margin-left: 8px;
                    }
                    &:hover{
                        outline: none;
                        border: 1px solid #2cb5b2;
        
                    }
                    input{
                        border: none;
                        margin: 0;
                        width: 100%;
                        height: 100%;
                        text-align: start;
                    }
                    .vb_icon{
                        display: flex;
                        align-items: center;
                        padding-right: 8px;
                        cursor: pointer;
                        svg{
                            color: #2cb5b2;
                        }
                    }
                 }
        
            }
            .other-login{
                margin-bottom: 17px;
                position: relative;
                img{
    
                    position: absolute;
                    left: 10px;
                    height: 16px;
                }
            }
            hr{
                width: 100%;
                border: 1px solid #2cb5b2;
                margin-bottom: 10px;
                margin-top: 10px;
            }
            p{
                font-size: 14px;
                color: #2A2A2A;
                font-weight:400;
                margin-bottom: 3px;
                text-align: start;
                span{
                    cursor: pointer;
                }
            }
            .other-login2{
                box-shadow: 0px 2px 3px 0px #0000002B, 0px 0px 3px 0px #00000015;
                background-color: white;  
                color: #0000008A;
              
            }
            .other-login3{
                background: #000000;
            }
            .other-login1{
                background: #1877F2;
    
            }
            .remember-forget{
                width: 100%;
                display: flex;
                font-size: 14px;
                align-items: center;
                gap: 8px;
                margin-bottom: 42px;
               
                    
                .checkbox-group{
                    display: flex;
                    gap: 20px;
                    @media screen and (max-width:500px) {
                        flex-direction: column;
                    }
                    label{
                        font-weight: 500px;
                    }
                    .checkbox_wrapper{
                        display: flex;
                        gap: 8px;
                        align-items: center;
                        position: relative;
                        cursor: pointer;
                        
                        .pet_checkbox{
                            height: 14px !important;
                            border-radius: 5px !important;
                            border: 1px solid #2CB5B2;
                            display: inline !important;
                            min-width: 14px !important;
                           
                        }
                        span{
                            font-weight: 500;
                        }
                        .flag{
                            display: flex;
                            align-items: center;
                            img{
                                position: inherit;
                                height: 24px;
                                object-fit: cover;
                                margin-right: 4px;
                            }
                        }
                        img{
                             height: 10px;
                             object-fit: cover;
                             position: absolute;
                             left: 2px;
                             top: 5px;
                        }
                        .pet_hidden_check:checked ~ img{
                            display: none;
                        }
                    }
            }
            }
            button{
                background-color: #2cb5b2;
                border-radius: 4px;
                padding:10px;
                border: none;
                color: white;
                cursor:pointer;
                height: 45px;
                width: 100%;
             }
            }
        .section2{
           .search-info{
            border: 1px solid #2cb5b2;
            padding: 33px 40px;
            border-radius: 5px;
            margin-top: 28px;
            .lert{
             
                padding-bottom: 33px;
                color: #F0A34C;
                text-align: center;
            
           }
           .intro{
            display: flex;
            gap:20px;
            margin-bottom: 40px;
            @media screen and (max-width: 700px) {
                flex-direction: column;
            }
            .img-container{
                height: 80px;
                width: 80px;
                margin-bottom: 30px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                   }
               }
               .details{
                color: #2CB5B2;
                h2{
                  font-size: 25px;
                  font-weight: 700;
                  text-align: start;
                }
                h4{
                  font-size: 18px;
                  font-weight: 400px;
                  text-align: start;
                }
                 .language{
                    display: flex;
                    gap: 2px;
                    margin-top: 20px;
                    img{
                        height: 20px;
                    }
                 }
               }
        }
        .details-info{
            margin-bottom: 30px;
            .vet-address{
                display: flex;
                gap: 8px;
                align-items: baseline;
                
                .logo{
                    display: flex;
                    align-items: center;
                    img{
                        height: 22px;
                     }
                }
                .info{
                    p{
                        font-size: 18px;
                        font-weight: 700;
                        text-align: start;
                        margin-bottom: 5px;
                    }
                }
               }
               .excpt{
                display: flex;
                gap: 8px;
                 align-items: center !important;
                 .logo{
                    margin-top: 2px;
                    img{
                        height: 18px;
                        margin-right: 8px;
                    }
                 }
                 .info{
                    .address{
                        font-size: 18px;
                        font-weight: 500;
                        text-align: start;
                        margin-bottom: 5px;
                    }
                 }
               }
            .address-detail{
                text-align: start;
                .address1{
                    font-size: 20px;
                    font-weight: 700;
                    span{
                        color: #2CB5B2;
                    }
                }
                
            }
    
        }
        .button-edit{
            padding: 8px;
            height: 52px;
            margin: 0 auto;
            border: none;
            border-radius: 5px;
            background-color: #2cb5b353;
            color: #2CB5B2;
            width: 80%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            img{
                height: 16px;
            }
        }
    }
        }
    }
    

    }
}