.grid-timetable-container {
    overflow-x: auto;
    padding: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
  .table-contents {
    display: flex;
    gap: 12px;
    .column {
      .day {
        height: 30px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          font-size: 14px;
          font-weight: 600;
        }
        .checkbox-global
          .checkbox_box
          input[type="checkbox"]:checked
          + label
          .disk {
          float: right;
          background: #8b7bcb;
          margin: -1px;
        }
        .checkbox-global .checkbox_box input[type="checkbox"]:checked + label {
          border: 1px solid #8b7bcb;
        }
      }
      .slot {
        display: flex;
        gap: 5px;
        button {
          height: 20px;
          width: 40px;
          border: none;
          display: grid;
          margin-bottom: 5px;
          place-content: center;
          background: #8b7bcb26;
          cursor: pointer;
          &.activate {
            background: #8b7bcb;
          }
          &.disponible {
            background: #b7b7b7;
          }
        }
        .hours {
          border: none;
          cursor: none;
          width: 42px;
          background: transparent;
        }
        .indicater {
          border: none;
          background: transparent;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
