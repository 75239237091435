.banner-home-container{
    background-image: url('../../../assets/Images/header-piture_small\ \(1\).jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
    height: 568px;
    width: 100%;
    display: grid; 
    text-align: center;
    place-content: center;
    position: relative;
    @media screen and (max-width:600px) {
        background:linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.5) 100%), url('../../../assets/Images/mobo_img.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 413px !important;
        place-content: end;
        padding-bottom: 15px;
    }
    h1{
        font-size: 55px;
        @media screen and (max-width:800px) {
            font-size: 45px;
            line-height: 1;
            padding: 0 59px;
        }
    }
    span{
        font-size: 30px;
        @media screen and (max-width:800px) {
            font-size: 28px;
            line-height: 1;
            padding: 10px 20px;
        }
    }
    .form_search{
        height: 54px;
        width: 85vw;
        margin: auto;
        border:1px solid white;
        margin-top: 68px;
        border-radius: 50px;
        background-color: white;
        border: none;
        display: flex;
         @media screen and (max-width:600px) {
         display: none;
        }
        form{
            flex: 1;
        display: flex;
        height: 100%;
        @media screen and (max-width:600px) {
            flex-direction: column;
            color: white !important;
        }
          .input_container{
           height: 100%;
           display: flex;
           align-items: center;
           border-right: 1px solid #2CB5B2;
           margin-right: 14px;
           flex: 1;
           div{
            width: 100%;
           }
           @media screen and (max-width:600px) {
         img{
            border-radius: 50%;
            background: white;
            height: 25px;
            width: 25px;
            padding: 4px;
         }
        }
            &:nth-child(3){
                border: none;
            }
            &:first-child{
                margin-left: 14px;
                   @media screen and (max-width:600px) {
                            margin-left: 0px;
            }
            }
           input{
            height: 100%;
            padding: 7px;
            outline: none;
            border: none;
            font-size: 11px;
            text-transform: uppercase;
            width: 100%;
            &::placeholder{
                color: #2A2A2A;
                font-weight: 600;
                
            }
              @media screen and (max-width:600px) {
                background-color: transparent;
                color: white;
                &::placeholder{
                    color: white;
                }
        
        }  
        &:hover{
            &::placeholder{
                color: rgba(0, 0, 0, 0.6);
            }
           }
        }
           select{
            height: 100%;
            padding: 7px;
            outline: none;
            border: none;
            width: 100%;
            color: #2A2A2A;
            font-weight: 600;
            text-transform: uppercase;
            background-color: rgba(0,0,0,0);
            font-size: 11px;
            @media screen and (max-width:600px) {
             background-color: transparent;
             color: white;
        }
        &:hover{
            
            color: rgba(0, 0, 0, 0.6);
       }
        }
           
           div{
            input{
                height: 100%;
            padding: 7px;
            outline: none;
            border: none;
            width: 100%;
              @media screen and (max-width:600px) {
           background-color: transparent;
           color: white;
        
        }
            }
           }
          }
             .submitButton{
            background-color: #2CB5B2;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 0;
            gap: 10px;
            border:none ;
            border-radius: 0 50px 50px 0;
            button{
                background-color: #2CB5B2;
                border: none;
                color: white;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                  @media screen and (max-width:600px) {
           background-color: transparent;
           color: white;
        }
            }
              @media screen and (max-width:600px) {
           background-color: transparent;
           border: 1px solid white;
           border-radius: 50px;
           img{
            background-color: #2CB5B2;
           }
        }
           }
        }
    }
}