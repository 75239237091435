* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins", open sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.flex-container {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 800px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .detail{
      flex: 1;
      width: 100%;
    }
  }
}
.border {
  border: 1px solid !important;
}
.border-radius {
  border-radius: 5px !important;
}

.mg-bm-6 {
  margin-bottom: 6px !important;
}
.mg-bm-50 {
  margin-bottom: 50px !important;
}
.mg-bm-40 {
  margin-bottom: 45px !important;
}
.padding-special {
  padding-left: 38px !important;
  padding-right: 38px !important;
}
.strong {
  font-weight: 600;
}
.lighter {
  font-weight: lighter;
}
.blakish {
  color: #2a2a2a;
}
.main-color {
  color: #2cb5b2 !important;
}
.text-center {
  text-align: center !important;
}
.white {
  color: white !important;
}
.black {
  color: black !important;
}
.lg-size {
  font-size: 40px !important;
  @media screen and (max-width: 700px) {
    font-size: 32px !important;
  }
}
.time,
.date {
  min-width: 140px;
}
.error_message {
  color: rgba(238, 73, 73, 0.871);
  font-size: 14px;
  padding-bottom: 14px;
  font-weight: bold;
  margin-top: -9px;
}
.link_forchange {
  text-decoration: none;
  margin: 20px;
  padding: 50px 0;
  color: #2cb5b2 !important;
  font-weight: bold;
}
.logout_btn {
  padding: 10px;
  margin: 20px;
  border: none;
  border-radius: 4px;
  background-color: #072625;
  color: white;
  cursor: pointer;
  &:hover {
    color: #072625;
    background-color: #2cb5b2;
  }
}
.forget_password {
  text-decoration: none;
  color: #2cb5b2 !important;
  font-weight: bold;
  font-size: 14px;
}
.checkbox-global {
  align-items: center;
  gap: 2px;
  .checkbox_title {
    margin-bottom: 8px;
  }
  .checkbox_box {
    label {
      width: 40px;
      height: 20px;
      background: #e6e6e6;
      border-radius: 40px;
      transition: all 0.5 ease;
      cursor: pointer;
      display: block;
      .disk {
        background-color: #b7b7b7;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    input[type="checkbox"]:checked + label {
      border: 1px solid #2cb5b2;
      .disk {
        float: right;
        background: #2cb5b2;
        margin: -1px;
      }
    }
  }
}
.submit-vet {
  margin: 35px 0;
}
.googleAuth {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: transparent !important;
  color: #2cb5b2 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin: 0 auto;
  img {
    height: 30px;
    width: 30px;
    object-fit: cover;
  }
}


.font-16 {
  font-size: 16px;
}
.font-20 {
  font-size: 20px;
}

.authbtn-group {
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  button {
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    height: 45px;
    padding: 10px;
    width: 100%;
  }
  .other-login {
    margin-bottom: 17px;
    position: relative;
    img {
      position: absolute;
      left: 10px;
      height: 16px;
    }
  }
  .other-login2 {
    box-shadow: 0px 2px 3px 0px #0000002b, 0px 0px 3px 0px #00000015;
    background-color: white;
    color: #0000008a;
  }
  .other-login3 {
    background: #000000;
  }
  .other-login1 {
    background: #1877f2;
  }
}

.center {
  width: 100%;
  display: grid;
  place-content: center;
}

.except_class {
  margin: 0 !important;
  background: white !important;
  .vet_attach_info {
    display: flex;
    align-items: center;
    gap: 14px;
    font-weight: bold;
    .h4 {
      color: #2cb5b2;
    }
    .vet_img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
.more {
  padding-left: 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: lighter;
  padding-bottom: 5px;
  font-size: 14px;
  text-align: center;
  span {
    color: #2cb5b2;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form_search_mobo {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    bottom: 0;
    width: 100%;
    height: 256px;
    border-radius: 0;
    background: #2cb5b2;
    padding: 10px 0;
  }
  form {
    flex: 1;
    display: flex;
    height: 100%;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      color: white !important;
    }
    .input_container {
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid #2cb5b2;
      flex: 1;
      padding:0 10px;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        border-bottom: 1px solid white;
      }
      &:nth-child(2) {
        border-bottom: 1px solid white;
      }
      div {
        width: 100%;
      }
      @media screen and (max-width: 600px) {
      }
      &:nth-child(3) {
        border: none;
      }
      &:first-child {
        margin-left: 14px;
        @media screen and (max-width: 600px) {
          margin-left: 0px;
        }
      }
      input {
        height: 100%;
        padding: 7px;
        outline: none;
        border: none;
        width: 100%;
        &::placeholder {
          color: #2a2a2a;
          font-weight: 400;
          font-family: "Poppins", open sans;
          
        }
        
        @media screen and (max-width: 600px) {
          background-color: transparent;
          color: white;
          width: 300px;
          &::placeholder {
            color: white;
            font-family: "Poppins", open sans;
            font-weight: 600;
          }
        }

        &:hover{
            &::placeholder {
              color: #5d5a5a;
            }
        }
      }
      select {
        height: 100%;
        padding: 7px;
        outline: none;
        border: none;
        width: 100%;
        @media screen and (max-width: 600px) {
          width: 150px;
          background-color: #2cb5b2;
          color: white;
          font-family: "Poppins", open sans;
          font-weight: 600;
        }
      }
      div {
        @media screen and (max-width: 600px) {
          color: white;
          width: 200px;
        }
        input {
          height: 100%;
          padding: 7px;
          outline: none;
          border: none;
          width: 100%;
          @media screen and (max-width: 600px) {
            color: white;
            width: 200px;
          }
        }
      }
    }
    .submitButton {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0 20px;
      gap: 10px;
      border: none;
      border-radius: 0 50px 50px 0;
      button {
        background-color: white;
        border: none;
        color: #2cb5b2;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          background-color: transparent;
          color: #2cb5b2;
        }
      }
      @media screen and (max-width: 600px) {
        background-color: white;
        border: 1px solid white;
        border-radius: 50px;
      }
    }
  }
}

.prv-mobo {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    button {
      padding: 12px;
      width: 100%;
      height: 52px;
      border: 1px solid #2cb5b2;
      border-radius: 5px;
      margin-bottom: 15px;
      background-color: #2cb5b2;
      color: white;
      cursor: pointer;
    }
  }
}
.prv-web {
  display: block;
  button {
    padding: 12px;
    width: 100%;
    height: 52px;
    border: 1px solid #2cb5b2;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #2cb5b2;
    color: white;
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.timetable-details {
  margin-left: 10px;
  h1 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 24px;
    color: #2a2a2a;
  }
  .legend {
    margin-bottom: 10px;
    font-family: "Poppins", open sans;
    font-weight: 600;
    .legend-item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      .legend-item-color {
        height: 20px;
        width: 20px;
        background-color: #8b7bcb;
        border-radius: 5px;
        &.color2 {
          background: #8b7bcb26;
        }
        &.color3 {
          background: #b7b7b7;
        }
      }
      .legend-item-text {
        font-size: 14px;
        font-weight: 600;
        color: #2a2a2a;
      }
    }
  }
}
.gap120 {
  gap: 120px !important;
}
.align-start {
  align-items: flex-start !important;
}

button {
  text-transform: uppercase !important;
}

.bg_purple_strong {
  background: #8b7bcb !important;
}

.bg_purple_light {
  background: #8b7bcb26 !important;
}

.text_purple {
  color: #8b7bcb !important;
}

.bold {
  font-weight: 600 !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.border_none {
  border: none !important;
}
.clinic-pages {
  .main-color {
    color: #8b7bcb !important;
  }
  .label-check .checkbox_group .checkbox_wrapper .pet_checkbox {
    height: 14px !important;
    border-radius: 5px !important;
    border: 1px solid #8b7bcb !important;
    display: inline !important;
    min-width: 14px !important;
  }
  .checkbox-global .checkbox_box input[type="checkbox"]:checked + label .disk {
    float: right;
    background: #8b7bcb !important;
    margin: -1px;
  }
  .checkbox-global .checkbox_box input[type="checkbox"]:checked + label {
    border: 1px solid #8b7bcb !important;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .clinic-dashboard-container
    .content-container
    .rendezvous-passes
    .rendezvous_item {
    margin-bottom: 25px;
    border: 1px solid #8b7bcb !important;
    padding: 12px;
    min-height: 160px;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    font-size: 14px;
    gap: 40px;
  }
  .clinic-dashboard-container
    .content-container
    .rendezvous-passes
    .btn_action {
    width: 300px;
    padding: 8px;
    height: 54px;
    border: 1px solid #8b7bcb !important;
    font-weight: bold;
    font-size: 14px;
    color: black;
    background-color: transparent;
    margin-top: 20px;
    cursor: pointer;
    display: block;
  }
  .clinic-dashboard-container
    .content-container
    .rendezvous-passes
    .rendezvous_item
    .rendezvous_action
    button {
    width: 100%;
    padding: 8px;
    height: 54px;
    background-color: #8b7bcb !important;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: whitesmoke;
    margin-top: 20px;
    cursor: pointer;
  }
  .clinic-dashboard-container
    .content-container
    .rendezvous-passes
    .rendezvous_item
    .rendezvous_img
    .img_container {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 2px solid #8b7bcb !important;
  }

  .clinic-dashboard-container
    .content-container
    .rendezvous-passes
    .rendezvous_item
    .item_data
    svg {
    color: #8b7bcb !important;
    margin-right: 10px;
  }
  .clinic-dashboard-container
    .content-container
    .rendezvous-passes
    .filter-container
    .filter-item
    .search-inputs
    svg {
    color: #8b7bcb !important;
  }
  .clinic-dashboard-container
    .content-container
    .change-form
    form
    .gap-10
    .input-container
    .link_container
    svg {
    color: #8b7bcb !important;
  }
}

@media screen and (max-width: 600px) {
  .search-container .intro {
    margin-bottom: 30px;
    padding-left: 27px;
    padding-top: 27px;
    padding-right: 27px;
  }
  .search-container .intro h3 {
    font-size: 30px;
    font-weight: 600;
  }
  .search-container p {
    font-size: 20px;
  }
  .no-data {
    padding-left: 27px;
  }
}

.sp-header {
  font-size: "25px";
  font-weight: "600";
}

.side-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  svg {
    size: 14px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.svg_purple {
  svg {
    color: #8b7bcb !important;
  }
}
.svg_purple2 {
  color: #8b7bcb !important;
}
.svg_blue {
  color: #2cb5b2 !important;
}
.margin-bottom-26 {
  margin-bottom: 26px !important;
}
.margin-bottom-13 {
  margin-bottom: 13px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.input-icon {
  border: 1px solid #2cb5b2 !important;
  border-radius: 5px !important;
  padding: 10px !important;
  align-items: center;
  gap: 8px;
  height: 52px !important;
  input {
    border: none !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    margin-top: 0 !important;
  }
}

.max-width-520 {
  max-width: 520px !important;
}
.border_purple {
  border-color: #8b7bcb !important;
}
.hidden {
  visibility: hidden;
}
.h2_fiche {
  font-size: 18px;
  font-weight: 600;
  color: #2a2a2a;
  margin-bottom: 20px;
  span {
    font-weight: 400;
    margin-left: 6px;
  }
}
button {
  cursor: pointer !important;
}

.activer_border {
  border: 2px solid #6a5aac;
}

.label_pic_text{
  font-size: "13px" !important;
  text-align: "center" !important;
  display: "block" !important;
  font-weight: "600" !important;
  cursor: "pointer" !important;
  border: "none" !important;
  background: "white" !important;
  height: "auto" !important;
}
.height-35 {
  min-height: 35px !important;
}

.prev-rendezvous-btn {
  background-color: white;
  padding: 10px ;
  font-size: 14px ;
  font-weight: 600 ;
  cursor: pointer ;
  min-height: 52px ;
  position: absolute;
  bottom: 0;
  width: 300px;
  max-width: 300px ;
  border: 1px solid #2cb5b2 ;
  color: #2a2a2a;
  @media screen and (max-width: 1300px) {
    display: none;
  }
  &.mobo-btn {
    display: none;
    position: relative;
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
    @media screen and (max-width: 1300px) {
      display:block;
    }
  }
  &.upbtn{
    background: #2cb5b2 !important;
    color: white;
  }
}
.add-photo-animal{
  transform: scale(0.4);
}

.poppins{
  font-family: "Poppins" , open sans !important;
}

.blinker{
  position: absolute;
  width: 10px;
  height: 10px;
  background: #8B7BCB;
  border-radius: 100%;
  top: -4px;
  left: -5px;
  animation: blinker 1s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
    
  }
}
.address_map_info{
  span{
  display: block;
  color: #2a2a2a;
  font-weight: 500;
    &:first-child{
      color: #2a2a2a;
      font-weight: 700;
    }
   
  }
}
.annule-special{
  font-weight: 500 !important;
  font-size: 17px !important;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Poppins", open sans !important;
}
.fnt-14{
  font-size: 14px !important;
}
.telNumber{
  text-decoration: none;
  color: #2cb5b2;
}
.info-actio-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  button{
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    width: 80px;
    &:first-child{
      border: 1px solid #8b7bcb;
    }
    
    &:last-child{
      background: #8b7bcb;
      color: white;
    }
    &.customerBtn{
      background: #2cb5b2;
    }
    &.customerBtnlight{
      border-color:  #2cb5b2;
    }
  }
}

.assign-attrb{
  h3{
    font-size: 18px;
    color: #2a2a2a;
    font-weight: 500;
    margin-bottom: 8px;
  }
  button{
    border-radius: 5px;
    padding: 15px;
    border: none;
    cursor: pointer;
    background-color: #8b7bcb;
    color: white;
    max-width: 200px;
  }
}

select{
  appearance: none;
  background-image: url('./assets/Icons/arrowselect.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: 13px !important;
  min-width: 70px;
}

.clinic-pages{
  svg{
    color: #8b7bcb !important;
  }
  i{
    color: #8b7bcb !important;
  }
  select{
    appearance: none;
    background-image: url('./assets/Icons/arrowselectpurple.svg') !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
    background-size: 13px !important;
    min-width: 70px;
  }
  .send-input{
    border-color: #8B7BCB !important;
    button{
      background-color: #8B7BCB !important;
      color: white !important;
      svg{
        color: white !important;
      }
    }

  }
  .Toastify__toast{
    svg{
      color: white !important;
    }
  }
  .menu-container{
    select{
      appearance: none;
      background-image: url('./assets/Icons/arrowwhite.svg') !important;
      background-repeat: no-repeat !important;
      background-position: right !important;
      background-size: 13px !important;
      min-width: 70px;
    }
    svg{
      color: white !important;
    }
   i{
    color: white !important;
    } 
  }
}

.sub-nondetail{
  svg{
    color: #9F9F9F !important;
  }
}
.form_search_mobo{
font-family: "Poppins", open sans;
font-weight: 600;
 select{
    background-image: url('./assets/Icons/arrowwhite.svg') !important;
 }
}
.error_message{
  text-align: start;
}

.css-1rby567{
  @media screen and (max-width: 500px) {
    width: 350px !important;
    
  }
}

.mobo-num{
  @media screen and (max-width: 400px) {
    display: block;
    width: 100% !important;
    
  }
}
.mobo-loader-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

*[disabled="disabled"] {
    color: #933;
    background-color: #ffc;
}


.phoneCode{
  padding-left: 45px !important;
}

.lgn_change{
  appearance: none !important;
  background-image: none !important;
}

.check_basic_info{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectDiv{
  border: 1px solid #2CB5B2;
  height: 52px;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 15px;


}

input[type="date"] {
  min-width: 300px !important;
}