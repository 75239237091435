.notfound_container{
   background-image: url('../../../assets/Images/header-piture_small\ \(1\).jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 100vh;
    width: 100%;
    display: grid;
    place-content: center;
    text-align: center;
}