.services {
  border: 1px solid #8B7BCB;
  min-height: 300px;
  padding: 8px;
 
  // .service-column{
  //     display: flex;
  //     .service-data-container{
  //         flex: 7;
  //          .service-data{
  //         display: flex;
  //         margin-bottom: 8px;
  //         align-items: baseline;
  //         div{
  //             padding: 8px;
  //             h3{
  //                 color: #8B7BCB;
  //                 font-size: 16px;
  //             }
  //             button{
  //                 width: 150px;
  //             }
  //         }
  //         .first-column{
  //            flex: 2;
  //         }
  //         .second-column{
  //            flex: 10;
  //            ul{
  //             list-style: none;
  //             li{
  //                span{
  //                 font-weight: bold;
  //             }
  //             }

  //            }
  //         }
  //         .third-column{
  //            flex: 2;
  //         }
  //     }
  //     }
  //    .service-action{
  //          flex: 4;
  //              display: flex;
  //              flex-direction: column;
  //              @media screen and (max-width:500px) {
  //                 flex-direction: column;
  //              }
  //         button{
  //             width: 150px;
  //             padding: 8px;
  //             background: #8B7BCB;
  //             color: whitesmoke;
  //             margin-bottom: 8px;
  //             border: none;
  //             height:43px;
  //             display:block;
  //             align-self: flex-end;
  //             border-radius: 5px;
  //             cursor: pointer;
  //             &.duplicate{
  //                 background: transparent;
  //                 border: 1px solid #8B7BCB;
  //                 color: #8B7BCB;
  //             }
  //             &.delete{
  //                 border: none;
  //                 background-color: transparent;
  //                 color: red;
  //                 display: flex;
  //                 align-items: center;
  //                 padding: 0;
  //                 height: 20px;
  //                 margin-top: 8px;

  //                 img{
  //                     width: 20px;
  //                     height: 22.5px;
  //                     object-fit: cover;
  //                     margin-right: 3px;
  //                 }
  //             }

  //         }
  //     }
  // }
}

.label-check {
  display: flex;
  gap: 40px;
  align-items: center;
  span {
    font-size: 18px;
    max-width: 305px;
    flex: 1;
    font-family: "Poppins", open sans;
  }
  .mini-input {
    max-width: 182px;
    min-width: 80px;
    text-align: center;
  }
  .checkbox_group {
    display: flex;
    gap: 20px;
    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    .checkbox_wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      position: relative;
      cursor: pointer;
      height: 25px;

      .pet_checkbox {
        height: 14px !important;
        border-radius: 5px !important;
        border: 1px solid #8B7BCB;
        display: inline !important;
        min-width: 14px !important;
      }
      .flag {
        display: flex;
        align-items: center;
        img {
          position: inherit;
          height: 24px;
          object-fit: cover;
          margin-right: 4px;
        }
      }
      img {
        height: 10px;
        object-fit: cover;
        position: absolute;
        left: 2px;
        top: 5px;
      }
      // .pet_hidden_check:checked ~ img{
      //     display: block;
      // }

      // .pet_hidden_check:not(:checked) ~ img {
      //     display: none;
      //   }
    }
  }
}

.services-wrapper {
  display: flex;
  gap: 50px;
  width: 100%;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    .service_form_container {
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      height: fit-content !important;
    }
  }
  .service_form_container {
    // border: 1px solid #8B7BCB;
    margin-top: 25px;
    width: calc(100% - 350px);
    height: fit-content;
    max-width: 1078px;
    .sub-nondetail{
      border: 1px solid #9F9F9F;
      font-size: 22px;
      font-weight: 600;
      color: #9F9F9F;
      cursor: pointer;
      padding: 10px;
      height: 100px;
      display: flex;
      align-items: center;
      background: #f1f1f1;
      display: flex;
      justify-content: space-between;
      svg{
        font-size: 35px !important;
      }
      
      &:first-child{
        border-radius: 5px;
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child{
        border-radius: 5px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .img_container{
        display: flex;
        align-items: center;
        gap: 10px;
        span{
          font-size: 16px;
        }
      }
    }
    .sub-container{
      border: 1px solid #8B7BCB;
      
      &:first-child{
        border-radius: 5px;
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:nth-child(2){
        border-top: none;
        border-bottom: none;
      }
      &:last-child{
        border-radius: 5px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .create-service {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      height: 80px;
      background: #2cb5b368;
      align-items: center;
      margin: 25px 0;
      margin-top: 0;
      border-bottom: 2px solid #8B7BCB;
      .title_service {
        h3 {
          color: #8B7BCB;
          font-weight: 600;
          font-family: "Poppins", open sans;
          font-size: 22px;
        }
        span {
        }
      }

      .title_action {
        display: flex;
        gap: 25px;
        img{
          object-fit: contain;
          cursor: pointer;
        }
        button {
          background: none;
          border: none;
          gap: 6px;
          font-family: 'Poppins', sans-serif !important;
          cursor: pointer;
          &.active_btn {
            background: #8B7BCB;
            color: whitesmoke;
            max-width: 150px;
            min-width: 130px;
            padding: 8px;
            height: 45px;
            border-radius: 5px;
          }
        }
        button.vet {
          background: none;
          font-family: 'Poppins', sans-serif !important;
          border: none;
          gap: 6px;
          cursor: pointer;
          &.active_btn {
            color: whitesmoke;
            padding: 8px;
            height: 45px;
            border-radius: 5px;
          }
          .editIcon {
            height: 50px;
            width: 50px;
            display: grid;
            place-content: center;
            background-color: #8B7BCB;

          }
        }

      }
    } 
    .service-form {
      display: grid;
      gap: 30px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 20px;
      .vet-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        height: 73px;
        background: #8b7bcb10;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
        .vet_attach_info {
          color: #8b7bcb;
          display: flex;
          gap: 12px;
          align-items: center;
          .vet_img {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            border: 2px solid #8b7bcb;
            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
        .vet_checker_info {
          .checkbox_group {
            display: flex;
            gap: 20px;
            align-items: center;

            @media screen and (max-width: 500px) {
              flex-direction: column;
            }
            .checkbox_wrapper {
              display: flex;
              gap: 8px;
              align-items: center;
              position: relative;
              cursor: pointer;
              height: 25px;

              .pet_checkbox {
                height: 14px !important;
                border-radius: 5px !important;
                border: 1px solid #8b7bcb;
                display: inline !important;
                min-width: 14px !important;
              }
              img {
                height: 10px;
                object-fit: cover;
                position: absolute;
                left: 3px;
                top: 5px;
              }
              // .pet_hidden_check:checked ~ img{
              //     display: block;
              // }

              // .pet_hidden_check:not(:checked) ~ img {
              //     display: none;
              //   }
            }
          }
        }
      }
      input {
        min-width: 150px;
        width: 50%;
        height: 52px;
        border: 1px solid #8b7bcb;
        padding: 8px;
        border-radius: 5px;
        outline: none;
      }
      select {
        min-width: 150px;
        width: 50%;
        height: 52px;
        border: 1px solid #8b7bcb;
        padding: 8px;
        border-radius: 5px;
        outline: none;
        outline: none;
      }
      textarea {
        min-width: 300px;
        width: 50%;
        min-height: 300px;
        max-height: 300px;
        border: 1px solid #8B7BCB;
        padding: 8px;
        border-radius: 5px;
        background-color: #fafafa;
        font-family: "Poppins", open sans;
        resize: none;
        outline: none;
      }
      .image_container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
        gap: 30px;
        .label{
            img{
                height: 30px !important;
            }
          }
        .img-box {
          height: 80px;
          width: 80px;
          border-radius: 5px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
            border: 1px solid 
            #8B7BCB;
          }
        }
        label {
          height: 80px;
          width: 80px;
          border-radius: 5px;
          border: 1px solid #8B7BCB;
          display: grid;
          place-content: center;
          background: #eeeeee;
          cursor: pointer;
        }
      }
    }
    .add-new-service {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      border-radius: 5px;
      gap: 8px;
      color: #8B7BCB;
      background: #eeeeee;
      margin: 25px 0;
      font-weight: bold;
      cursor: pointer;
    }
    .time_table_container {
      padding: 25px;
    }
  }
  .service_list {
    max-width: 312px;
    .list {
      display: flex;
      flex-direction: column-reverse;
      .list-item {
        display: flex;
        width: 312px;
        border: 1px solid #8B7BCB;
        border-radius: 5px;
        height: 70px;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        margin-bottom: 14px;
        .list-item-name {
          font-weight: bold;
          color: #8B7BCB;
          height: 100%;
          display: grid;
          place-content: center;
          cursor: pointer;
          flex: 1;
          justify-content: flex-start;
        }
        .list-item-action {
          display: flex;
          align-items: center;
          gap: 12px;

          button {
            img {
              height: 20px;
              object-fit: cover;
            }
            cursor: pointer;
            border: none;
            background: transparent;
          }
        }
      }
      .add-list-item {
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        width: 312px;
        border: 1px solid #8B7BCB;
        border-radius: 5px;
        height: 70px;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        img {
          height: 20px;
          object-fit: cover;
        }
      }
    }
  }
}

.top-25 {
  margin-top: 22px !important;
}

.image_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  gap: 30px;
  label{
    img{
        height: 30px !important;
    }
  }
  .img-box {
    height: 80px;
    width: 80px;
    border-radius: 5px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      border: 1px solid #8B7BCB;
    }
  }
  label {
    height: 80px;
    width: 80px;
    border-radius: 5px;
    border: 1px solid #8B7BCB;
    display: grid;
    place-content: center;
    background: #eeeeee;
    cursor: pointer;
  }
}
.search-bar{
  border: 1px solid #8B7BCB;
  border-radius: 5px;

 .container{
  display: flex;
  align-items: center;
  height: 52px;
  input{
    border: none !important;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  img{
    height: 20px;
    object-fit: cover;
    margin: 0 8px;
    cursor: pointer;
  }
 }
}
