.time_table_container {
  position: relative;
  .time_table {
    width: auto;
    position: relative;
    overflow-x: auto;
    border: 1px solid #8b7bcb;
    place-content: center;
    padding: 40px 0;
    padding-top: 0;
    padding-bottom: 10px;
    .timetable_setting_container {
      padding-left: 20px;
    }
    .timetable_action {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 60px;
      background: #8b7bcb;
      font-family: "Poppins", open sans;
      margin-bottom: 40px;
      position: sticky;
      right: 0;
      left: 0;
      top: 0;
      width: 100%;
      div {
        cursor: pointer;
        color: white;
        height: 100%;
        width: 100%;
        display: grid;
        place-content: center;
        font-weight: 600;
        font-size: 22px;
        &.active {
          background: white;
          color: #8b7bcb;
          border: 1px solid white;
          border-radius: 5px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 0px;
          &:last-of-type {
            border-radius: 5px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
          }
        }
      }
    }
    .time-row {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      padding-left: 20px;
      .time-column {
        min-width: 50px;
        &.days {
          min-width: 100px;
        }
      }
      .time-line {
        max-width: 800px;
        height: 20px;
        background: #f2f2f2;
        border-radius: 40px;
        display: flex;

        .time-slot {
          width: calc(600px / 5);
          height: 55px;
          border-right: 1px solid #ebebeb;
          position: relative;
          &:last-of-type {
            border: none;
            max-width: 50px;
          }
          &:first-child {
            width: 50px;
          }
          span {
            background-color: white;
            float: right;
            transform: translate(50%, 0);
            &.big-slot {
              transform: translate(60%, 0);
            }
          }
          .range {
            position: absolute;
            width: calc(600px / 5);
            height: 20px;
            background: #2cb5b359;
            border-radius: 40px;
            display: flex;
            justify-content: space-between;
            z-index: 1;
            .time-disk {
              height: 20px;
              width: 20px;
              background-color: #8b7bcb;
              border-radius: 50px;
              position: relative;
              .time-box {
                position: absolute;
                top: -25px;
                height: 20px;
                width: 40px;
                left: -10px;
                display: flex;
                font-size: 10px;
                align-items: center;
                justify-content: center;
                z-index: 2;
                background: white;
                border-radius: 5px;
                border: 1px solid #8b7bcb;
                color: #8b7bcb;
              }
            }
          }
        }
        &.header {
          background: none;
        }
      }
    }
    .timetable_setting_container {
      margin-left: 20px;
      overflow: auto;
      margin-right: 13px;
      .input_detail {
        display: flex;
        gap: 8px;
        align-items: center;
        .reason {
          width: 240px;
        }
        .time-box {
          width: 140px !important;
        }
        input {
          width: 100%;
          padding: 8px;
          height: 40px;
          border: 1px solid #8b7bcb;
          border-radius: 5px;
          outline: none;

          &:focus {
            background-color: #8B7BCB26;
          }
        }
        select {
          padding-left: 8px;
          height: 40px;
          border: 1px solid #8b7bcb;
          border-radius: 5px;
          outline: none;
          width: 100%;
        }
        .in {
          width: 300px;
        }
        .date_section {
          width: 190px;
          align-self: baseline;
          input {
            width: 190px;
          }
        }
        .time_list {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
        }
        .action {
          margin-top: 10px;
          img {
            width: 18px;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
      .header_style {
        color: #8b7bcb;
        margin-bottom: 20px;
      }
    }
  }
}

.all-time {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  .checkbox_group {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    .checkbox_wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      position: relative;
      cursor: pointer;

      .pet_checkbox {
        height: 14px !important;
        border-radius: 5px !important;
        border: 1px solid #8b7bcb;
        display: inline !important;
        min-width: 14px !important;
      }
      img {
        height: 10px;
        object-fit: cover;
        position: absolute;
        left: 3px;
        top: 0px;
      }
      // .pet_hidden_check:checked ~ img{
      //     display: block;
      // }

      // .pet_hidden_check:not(:checked) ~ img {
      //     display: none;
      //   }
    }
  }
  .times-chechbox {
    height: 14px !important;
    width: 14px !important;
    border-radius: 5px;
  }
  span {
    font-size: 14px;
  }
}

.add-date {
  height: 72px;
  border-radius: 5px;
  background: #eeeeee;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #8b7bcb;
  font-weight: 700;
  svg {
    font-size: 42px;
  }
}

.special-action{
  display: flex;
  gap: 10px;
  margin-bottom: 80px;
  .validate-date {
    button {
    padding: 12px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: none;
    background: #8b7bcb;
    color: whitesmoke;
    font-weight: 600;
    }
  }
  .cancel-date {
    button {
    padding: 12px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: none;
    background: whitesmoke;
    color: #8b7bcb;
    font-weight: 600;
    border: none !important;
    }
  }

}

