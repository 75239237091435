.home_container{
    min-height: 300px;
    color: black;
    position: relative;
    .bg_container{
        position: absolute;
        width: 32%;
        left: 0;
        bottom: 0;
        top: 0;
        background-size: cover;
        background-repeat: none;
        background-position: center;
        height: 801px;
        @media screen and (max-width:500px){
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            top: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
        img{
            height: 100%;
            margin-top: 30px;
            @media screen and (max-width:500px){
                width: 100%;
            }
        }
    }
    .home_content{
        display: flex;
        position: relative;
        z-index: 1;
        gap: 80px;
        justify-content: center;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-right: 45px;
        padding-left: 45px;
         @media screen and (max-width:600px) {
                flex-direction: column;
                gap: 10px;
                padding: 20px;
            }
        .sub_content{
            width: 500px;
            @media screen and (max-width:600px) {
                width: auto;
            }
            .content_img{
                height: 294px;
                    img{
                        width: 100%;
                        height: 100%;
                    object-fit: contain;
                    }
         }
         h1{
            margin-top: 60px;
            margin-bottom: 15px;
         }
         p{
            min-height: 180px;
         }
         button{
            margin-top: 33px;
            padding: 15px 47px;
            background: transparent;
            border: 1px solid #2CB5B2;
            font-size: 14px;
            font-weight: 600;
            color:  #2A2A2A;
            cursor: pointer;
           &:hover{
            background: #2CB5B2;
            color: whitesmoke;
           }
           @media screen and (max-width:600px) {
            width: 100%;
           }
         }
            }
         
        }
}