.header-container{
    width: 100%;
    height: 90px;
    background-color:transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    position: fixed;
    z-index: 100;
    transition: all 0.5s ease;
    &.scrolled{
        background-color: #072625;;
    }
    @media screen and (max-width:(800px)) {
        padding: 0 20px;  
    }
    .logo-container{
        padding: 10px;
        width: 300px;
        @media screen and (max-width:(800px)) {
            height: 36px;
            padding: 0px;   
            width: 170px;
        }
        img{
            height: 100%;
            width: 100%;
            cursor: pointer;
            @media screen and (max-width:(500px)) {
               object-fit: contain;
            }
        }
    }
    .action-container{
        display: flex;
        gap: 40px;
        font-size: 14px;
        align-items: center;
       @media screen and (max-width:(1000px)) {
           gap: 5px !important;
           margin-left: 8px;
           }
           .action-btn{
            display: flex;
            cursor: pointer;
            svg{
            color:#2cb5b2;
            margin-left: 8px;
            font-size: 20px !important;
             &.svg-right-space{
               margin-right: 4px;
             }
          
           }
           .humberger-menu{
            font-size: 42px !important;
           }
           select{
            background:rgba(0,0,0,0);
            color: white;
            border: none;
            outline: none;
            cursor: pointer;
             option{
            cursor: pointer;
            color: black !important;
             &:focus{
                color: black;
             }
            }
           }
           @media screen and (max-width:(1050px)) {
            
            &.btn-hide{
                display: none;
            }
           }
        }
    }      
}
 .brands-container{
            gap: 10px !important;
            justify-content: flex-start !important;
            @media screen and (max-width:650px) {
                flex-direction: row !important;
            }
           .brands-icon{
            i{
                font-size: 14px;
                color: #2cb5b2;
                cursor: pointer;
            }
         }
         }