.search-container{
    color: black;
    width: 80vw;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    @media screen and (max-width: 700px) {
        width: 100%;
        padding-top: 0;
    }
    .intro{
        margin-bottom: 30px;
        h3{
            font-size: 40px;
            font-weight: 600;
            span{
             color:#2CB5B2;
             font-size: 40px;
            }
         }
    }
    p{  
        font-weight: 600;
        font-size: 18px;  
    }
    .search-card-container{
        display: flex;
        gap: 10px;
        padding: 30px;
        display: flex;
        
        @media screen and (max-width: 1355px) {
            display:flex;
            display: flex;
            margin-right: 10px;
            flex-direction: column-reverse;
        }
        .result-container{
            flex: 3;
            flex-direction: column-reverse;
            .info-result{
                margin-bottom: 40px;
                display: flex;
                gap: 20px;
                padding: 30px;
                border: 1px solid #2CB5B2;
                @media screen and (max-width: 1200px) {
                    display:flex;
                    flex-direction: column;
                }
                @media screen and (max-width: 600px) {
                    padding: 8px;
                    
                }
                .search-info{
                    min-width:240px;
                    max-width: 335px;
                    @media screen and (max-width: 1024px) {
                       min-width: 0;
                    }
                    @media screen and (max-width: 800px) {
                        max-width: 100% !important;
                        
                    }
                    .img-container{
                     height: 80px;
                     width: 80px;
                     margin-bottom: 30px;
                     @media screen and (max-width: 500px){
                        margin: 0 auto;
                        margin-bottom: 30px;
                    }
                     img{
                         height: 100%;
                         width: 100%;
                         object-fit: cover;
                         border-radius: 50%;
                        }
                    }
                    .vet-address{
                     display: flex;
                     gap: 8px;
                     align-items: baseline;
                     margin-bottom: 35px;
                     
                    }
                    .vet-schedule-meeting{
                     form{
                         display: flex;
                         flex-direction: column;
                         margin-top: 10px;
                         label{
                             font-size: 14px;
                             font-weight: 600;
                             margin-bottom: 9px;
                             font-family: 'Open Sans', sans-serif;
                         }
                         select{
                             height: 100%;
                             width: 100%;
                             padding: 8px;
                             border: none;
                             color: black;
                             background-color: white;
                             &:focus{
                                 outline: none;
                             }
                         }
                         button{
                             padding: 12px;
                             height: 52px;
                             border: 1px solid #2CB5B2;
                             border-radius: 5px;
                             margin-bottom: 15px;
                             background-color: #2CB5B2;
                             color: white;
                             cursor: pointer;
                         }
                     }
                    }
                 }
                 .search-time{
                    flex: 1;
                    margin-top: 120px;
                    @media screen and (max-width: 1200px) {
                        margin-top: 0;
                        
                    }
                   .calendar-container{
                    display: flex;
                    justify-content: center;
                    .calendar{
                        display: flex;
                        overflow-y:auto;
                        position: relative;
                        svg{
                            color: #2CB5B2;
                            font: 18px;
                            &:hover{
                                opacity: 0.7;
                            }
                        }
                        .column2{
                            display: flex;
                            gap: 10px;
                            position: relative;
                            .loader{
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                background-color: #2CB5B2;
                                opacity: 0.5;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 5px;
                                z-index: 1;
                            }
                            .day{
                                .header{
                                    h5{
                                        font-size: 13px;
                                        text-align: center;
                                        font-weight: bold; 
                                        color: #2A2A2A;
                                        font-weight: 900;

                                    }
                                    h6{
                                        text-align: center;
                                        font-weight: 400;  
                                        color: #2A2A2A;
                                    }
                                }
                                div{
                                    height: 30px;
                                    min-width: 60px;
                                    margin-bottom: 10px;
                                    display: grid;
                                    place-content: center;
                                    font-size: 12px;
                                }
                                .raw{
                                    border: 1px solid #2CB5B2;
                                    border-radius: 5px;
                                    cursor: pointer;
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #2CB5B2;
                                    width: 100px;
                                    height: 40px;
                                    @media screen and (max-width: 800px) {
                                        width: 80px;
                                        
                                    }
                                   

                                }
                                .inactive{
                                    opacity: 0.5;
                                    cursor: not-allowed;
                                }
                                .active{
                                    background-color: #2CB5B2;
                                    color: white;
                                }
                            }
                        }
                        .column1{
                          cursor: pointer;
                        }
                        .column3{
                            cursor: pointer;
                          }
                       }
                    }
                   }
                   p{
                    font-size: 14px;
                    text-align: center;
                    margin-top: 10px;
                    span{
                        color: #2CB5B2;
                    }
                    .more{
                        margin-bottom: 20px;
                    }
                 }
            }

        }
       
        .result-map{
            flex: 1;
            visibility: hidden;
            @media screen and (max-width: 1200px) {
                visibility: visible;
            }
            &.visible{
                visibility: visible;
                @media screen and (max-width: 1200px) {
                    display: block;
                }
            }
        }
        
    }
}
.confirm-card-container{
    color: black;
    display: grid;
    place-content: center;
    padding: 30px 10px;
    .holder{
        .notification{
           text-align: start;
           color: #F0A34C;
           padding: 30px 0;
           font-size: 18px;
           font-weight: 600;
           display: flex;
           align-items: flex-start;
           gap: 10px;
           justify-content: center;

        }
        .confirm-form{
          background-color: white;
          border-radius: 5px;
          border: 1px solid #2CB5B2;
          .tile{
            display: flex;
            border-bottom: 1px solid #2CB5B2;
            position: relative;
            .tick{
                position: absolute;
                left: -40px;
                height: 100%;
                display: grid;
                place-content: center;
                @media screen and (max-width:680px) {
                    display: none !important;
                }
                img{
                    height: 26px;
                    width: 26px;
                    object-fit: cover;
                }
            }
             &:last-of-type{
                border-bottom: none;
             }
            .number{
                min-height: 120px;
                background-color: #2cb5b39f;
                color: #2CB5B2;
                width: 53px;
                font-size: 25px;
                font-weight: 700;
                display: grid;
                place-content: center;
                text-align: center;
                img{
                    height: 26px;
                    width: 26px;
                    object-fit: cover;
                    display: none;
                    @media screen and (max-width:680px) {
                        display: block;
                        
                    }
                }
                &.active{
                    background-color:#2CB5B2;
                    color: white;
                }
                @media screen and (max-width:680px) {
                    width: 40px;    
                }
              }
            .content{
                width: 500px;
                padding: 20px;
                @media screen and (max-width:600px) {
                    width: 78vw;
                }
                p{
                    font-weight: 400;
                    font-size: 15px;
                    margin-bottom: 8px;
                }
                select{
                    width: 100%;
                    border-radius: 5px;
                    outline: none;
                    border: 1px solid #2CB5B2;
                    height: 52px;
                    padding: 8px;
                    color: black;
                    background-color: white;
                    &:focus{
                        outline: none;
                        border: 1px solid #2CB5B2
                    }
                }
                input{
                    width: 100%;
                    border-radius: 5px;
                    outline: none;
                    border: 1px solid #2CB5B2;
                    height: 52px;
                    padding: 8px;
                    cursor: pointer;
                    &:focus{
                        outline: none;
                        border: 1px solid #2CB5B2
                    }
                }
                .info-section{
                    display: flex;
                    gap: 8px;
                    @media screen and (max-width:800px) {
                        flex-direction: column;     
                    }
                    .image-holder{
                       height: 80px;
                       width: 80px;
                       border-radius: 50%;
                       overflow: hidden;
                       img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                       }

                    }
                    .info-holder{
                        .address{
                            font-size: 16px;
                            font-weight: 700;
                            display: flex;
                            color: #2A2A2A;
                            margin-bottom: 5px;
                            &:first-child{ 
                                font-weight: 400;
                            }
                           img{
                            height: 25px;
                            margin-right: 8px;
                           } 
                           span{
                            color: #2CB5B2;
                            margin-left: 4px;
                           }
                        }
                    }
                }
                .period{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @media screen and (max-width:800px) {
                        flex-direction: column;
                        align-items: self-start;
                    }
                    input{
                        border: none;
                        cursor: pointer;
                       
                    }
                    input[type="date"]::-webkit-calendar-picker-indicator {
                        color: #2CB5B2 !important;
                        position: relative;
                        opacity: 1;
                        cursor: pointer;
                        float: left;
                      }
                    button{
                        height: 50px;
                        padding: 8px;
                        width:120px;
                        background: #2CB5B2;
                        border-radius: 5px;
                        border: none;
                        cursor: pointer;
                        color: whitesmoke;
                    }
                }
            }

          }
      
        }
    }
}

.confirm-card-container-no-center{
  color: black;
  .holder{
    margin-bottom: 182px;
    .notification{
        text-align: start;
        color: #F0A34C;
        padding: 30px 0;
        font-size: 16px;
        font-weight: 600;
        padding: 21px;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        justify-content: center;
        img{
            align-self: flex-start;
        }
    }
    @media screen and (max-width: 600px) {
       
        
    }
 .detail-holder{
    display: flex;
    gap:20px;
    justify-content: center;
    padding-left: 170px;
    padding-right: 170px;
    position: relative;
    @media screen and (max-width:800px) {
        padding: 22px;
        flex-direction: column-reverse;
    }
    .main{
        flex: 1;
    }
    .main2{
        width: 85vw;
         @media screen and (max-width:700px) {
               margin: 0 auto;
            }
        .confirmed-box{
            @media screen and (max-width:700px) {
                display: flex;
                flex-direction: column;
                padding: 14px;
                .intro{
                    margin-bottom: 20px;
                    padding-left: 0 !important;
                    padding-top: 0 !important;
                
                }
            }
        }
       
    }
    .section{
      .checker{
         margin-bottom: 20px;
         p{
            color: #2CB5B2;
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 50px;
         }
         .pinfo{
      
            p{
                font-weight: 600;
                margin-bottom: 8px;
                color: black;
                font-size: 16px;
                span{
                    font-weight: 400;
                }
            }
         }
      }
      .checker1{
        min-height: 145px;
      }
      .checker2{
        min-height: 286px;
      }
      .search-info{
        .intro{
            padding: 0;
            display: flex;
            gap:20px;
            margin-bottom: 70px;
               @media screen and (max-width: 700px) {
                // flex-direction: column;
            }
            .img-container{
                height: 80px;
                width: 80px;
                margin-bottom: 30px;
                @media screen and (max-width: 500px){
                    margin: 0 auto;
                    margin-bottom: 30px;
                }
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                   }
               }
               .details{
                color: #2CB5B2;
                h2{
                    font-size: 25px;
                    font-weight: 700;
                    text-align: start;
                  }
                  h4{
                    font-size: 18px;
                    font-weight: 400px;
                    text-align: start;
                  }
                   .language{
                      display: flex;
                      gap: 2px;
                      margin-top: 20px;
                      img{
                          height: 20px;
                      }
                   }
               }
        }
        .details-info{
            margin-bottom: 30px;
            .vet-address{
                display: flex;
                gap: 8px;
                align-items: baseline;
                .logo{
                    img{
                        height: 22px;
                     }
                }
                .info{
                    p{
                        font-size: 18px;
                        font-weight:500;
                    }
                }
               }
               .excpt{
                display: flex;
                gap: 8px;
                 align-items: center !important;
                 .logo{
                    display: flex;
                    align-items: center;
                    img{
                        height: 18px;
                        margin-right: 8px;
                    }
                 }
                 .info{
                    .address{
                    font-weight: 700;
                    font-size: 16px;
                    }
                 }
               }
            .address-detail{
                .address1{
                    font-size: 20px;
                    font-weight: 700;
                    span{
                        color: #2CB5B2;
                    }
                }
                
            }

        }
        
        
     }
    .btn-confirm-action{
        display: flex;
        gap: 8px;
        @media screen and (max-width: 800px) {
            flex-direction: column;
            
        }
        button{
            flex: 1;
            padding: 13px;
            height: 54px;
            background-color: #2cb5b35f;
            border: none;
            font-weight: 600;
            color: #2CB5B2;
            cursor: pointer;
            &.active{
                background:#2CB5B2;
                color: whitesmoke;
            }
        }
    }
    .checkbox_group{ 
            display: flex;
            gap: 20px;
            margin-top: -30px;
            @media screen and (max-width:500px) {
                flex-direction: column;
            }
            .checkbox_wrapper{
                display: flex;
                gap: 8px;
                align-items: center;
                position: relative;
                cursor: pointer;
                
                .pet_checkbox{
                    height: 14px !important;
                    border-radius: 5px !important;
                    border: 1px solid #2CB5B2;
                    display: inline !important;
                    min-width: 14px !important;
                }
                .flag{
                    display: flex;
                    align-items: center;
                    img{
                        position: inherit;
                        height: 24px;
                        object-fit: cover;
                        margin-right: 4px;
                    }
                }
                img{
                     height: 10px;
                     object-fit: cover;
                     position: absolute;
                     left: 2px;
                     top: 5px;
                }
                // .pet_hidden_check:checked ~ img{
                //     display: none; 
                // }
            }
    }
    }
    .ruler{
        padding: 42px;
        border: 1px solid #2CB5B2;
        border-radius: 5px;
      }
      .confirmed-box{
        padding: 42px;
        border: 1px solid #2CB5B2;
        border-radius: 5px;
        margin-bottom: 20px;
        display: flex;
        gap: 30px;
        padding-bottom: 10px;
        padding-top: 20px;
      }
 }
}

}

.notf-container{
    height: 140px;
    padding: 8px;
    background-color: #2CB5B2;
    border-radius: 5px;
    display: grid;
    place-content: center;
    width: 85vw;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    p{
        color: whitesmoke !important;
    }
}